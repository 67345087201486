import React from "react";
import ReactPlayer from "react-player";
import useWindowDimensions from "../responsive";

const ServiceContent = [
  {
    // icon: "ti-headphone",
    suggestion:'Headphones',
    no: "01",
    service: "Protean Journey",
    title: "https://dmathmusic.com/sound1.mp3",
    desc: ` `,
  },
  {
       // icon: "ti-headphone",
       suggestion:'Speakers',
    no: "02",
    service: "Early Days of Purity",
    title: "https://dmathmusic.com/sound2.mp3",
    desc: ` `,
  },
  {
        // icon: "ti-headphone",
        suggestion:'Headphones',
    no: "03",
    service: "Winter Euphoria - Variations",
    title: "https://dmathmusic.com/sound3.mp3",
    desc: ` `,
  },
  {
        // icon: "ti-headphone",
        suggestion:'Headphones',
    no: "04",
    service: "Electric Forest Op.2 (Dark Version)",
    title: "https://dmathmusic.com/sound4.mp3",
    desc: ` `,
  },
  {
        // icon: "ti-headphone",
        suggestion:'Speakers',
    no: "05",
    service: "Perplexity Op.1 - Intro",
    title: "https://dmathmusic.com/sound5.mp3",
    desc: ` `,
  },
  // {
  //       // icon: "ti-headphone",
  //       suggestion:'Speakers',
  //   no: "06",
  //   service: "La Lungo Strada",
  //   title: "Photography design meeting clients needs.",
  //   desc: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
  //   eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  // },
];

const ServiceTwo = () => {

  const {width, height} = useWindowDimensions();



  return (
    <>
 <div className="row gy-2" style={{marginRight:0, marginLeft:'auto'}}>


  
       {ServiceContent.map((val, i) => (
     
         


            <div className="col-md-11">
       
             {!(i == 6 ) && 
              
              <div className="feature-box-02" style={{padding:'10px'}}>
              <h6>
                <span>{val.no}.</span> {val.service}
              </h6>
              {/* <h5>Recommended:     {val.suggestion}</h5> */}
              {width >= 400  &&   <p>{val.desc}</p>}
             
              <ReactPlayer url={val.title} disablePictureInPicture={true} controls={true} width="100%" height="40px" style={{margin:'auto',marginBottom:'0px'}}
                config={{ 
                  file: { 
                    attributes: {
                      controlsList: 'nodownload',
                    } 
                  } 
                }}
              />
            
            </div>
              }
              </div>

              
              

           
              
            
          
        ))
        
        }

{/* 
        <div className="d-flex justify-content-center gy-2">
    <div className="col-md-6" style={{display:'flex',justifyContent:'center'}}>
       
   
        <div className="feature-box-02" style={{padding:'15px'}}>
        <h6>
          <span>{ServiceContent[2].no}.</span> {ServiceContent[2].service}
        </h6>

        { window.screen.width >= 400  &&   <p>{ServiceContent[2].desc}</p>}
        <ReactPlayer url="https://dmathmusic.com/math/sound1.mp3" disablePictureInPicture={false} controls={true} width="100%" height="40px" style={{margin:'auto',marginBottom:'0px'}}
                config={{ 
                  file: { 
                    attributes: {
                      controlsList: 'nodownload',    
                    } 
                  } 
                }}
              />
      
      </div>
     
        </div>
        </div> */}
       
      </div>
    </>
  );
};

export default ServiceTwo;


  //  <div className="row gy-4" style={{backgroundColor:'red'}}>
  //       {ServiceContent.map((val, i) => (
  //         <div className="col-sm-6 col-lg-4" key={i}>
  //           {i== 1 || i== 4 || i == 0 || i == 3? <div className="">
              
  //             </div>: 
  //           <div className="feature-box-02">
  //             {/* <div className="icon">
  //               <i className={val.icon}></i>
  //             </div> */}
  //             <h6>
  //               <span>{val.no}.</span> {val.service}
  //             </h6>
  //             <h5>Recommended:     {val.suggestion}</h5>
  //             {/* <h5>{val.title}</h5> */}
  //             <p>{val.desc}</p>
  //             <ReactPlayer url="./joji.mp3" controls={true} width="100%" height="50px" style={{margin:'auto'}}/>
  //           </div>
  //           }
  //         </div>
          
  //       ))}
  //     </div>





  // <>
  // <div className="row gy-4 " style={{marginRight:0, marginLeft:'auto',backgroundColor:'red' }}>
 
 
  //   <div style={{display:'grid'}}>
  //       {ServiceContent.map((val, i) => (
      
 
 
  //            <div className="col-md-4" >
  //             {!(i == 0 ) && 
  //              <div className="col-md-12">
  //              <div className="feature-box-02" >
  //              <h6>
  //                <span>{val.no}.</span> {val.service}
  //              </h6>
  //              {/* <h5>Recommended:     {val.suggestion}</h5> */}
  //              <p>{val.desc}</p>
  //              <ReactPlayer url="./joji.mp3" controls={true} width="100%" height="40px" style={{margin:'auto',marginBottom:'0px'}}/>
  //            </div>
  //            </div>
  //              }
 
  //            </div>
               
             
           
  //        ))
         
  //        }
  //        </div>
  //      </div>
  //    </>