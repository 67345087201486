import React, {useEffect,useState} from "react";
import TextLoop from "react-text-loop";
import useWindowDimensions from "../responsive";
import Social from "../Social";
import Particles from "react-particles-js";







const Slider = () => {


  const {height, width} = useWindowDimensions();


  return (
    <>
      {/*  Home Banner */}
      <section
        id="home"
        // overlay 
        className="home-banner"
        // style={{
        //   backgroundImage: `url(${
        //     process.env.PUBLIC_URL + "img/banner/bg-1.jpg"
        //   })`,
        style={{
          backgroundImage: `url(${
            "https://dmathmusic.com/" + "img/banner/bg-1.jpg"
          })`,
          // backgroundSize:'cover',
          backgroundSize: 'cover',
          backgroundPosition: '62% 50%'
          
        }}
      >
       
        {/* End particle animation */}
        <div className="container">
          <div className="row align-items-center full-screen first-sect">
            <div className="col-lg-12" >
              <div className="hb-typo ">
                {/* 48px */}


                {width <= 993 && <div className="upsos" ></div>}
                <h1 className="font-alt h1-mobile" style={{ fontWeight:'normal', fontSize:'40px'}}>
  
                  <br />Dimitris Mathiopoulos<br />
                  {width <= 993 && <span style={{display:'block',whiteSpace:'pre'}}>      </span>}
                   Music{" "}
                  {/* <p color="white"> width : {width}</p> */}

                
               
                  <TextLoop>
                    <span className="loop-text" style={{fontStyle:'Ephesis'}}>Composer</span>
                    <span className="loop-text" style={{fontStyle:'Ephesis'}}> Creator</span>
                  </TextLoop>{" "}
                 
                </h1>

                {width <= 993 ?     <div className="btn-bar" style={{display:'flex',justifyContent:'center'}} >
                  <a className="px-btn px-btn-theme btn-md" href="#about">
                    Learn More
                  </a>
                </div> :
                    <div className="btn-bar" >
                    <a className="px-btn px-btn-theme btn-md" href="#about">
                      Learn More
                    </a>
                  </div>}
            
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-bottom go-to">
          {/* <a href="#about">Scroll</a> */}
        </div>
        {/* <Social /> */}
      </section>
      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
