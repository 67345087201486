import React, { useDebugValue } from "react";
import useWindowDimensions from "../responsive";


const About = () => {




  const {height, width} = useWindowDimensions();

  // console.log(navigator.userAgent.);
  return (
    <>
      <div className="row align-items-center justify-content-evenly biotech" >
      {/* style={{display:'flex', justifyContent:'space-around', width:'60%',borderRadius:'20px',margin:'auto',backgroundColor:'rgba(27,27,27,0.8)'}} */}


        {/* <div className="col-lg-2 my-3">
          <div className="img-box dark-img-box" style={{width:'350px', marginRight:'20px'}}> */}
            {/* <img src="img/about/about-5.jpg" alt="violin" /> */}
            {/* <img src="./color.jpg" alt="violin" style={{}}/> v
          </div>
        </div> */}
        

        <div className="col-lg-12 my-3 bio_text_container">
          <div className="typo-box about-me check-margin">
            {/* <h3>About</h3> */}
            <div className="sm-title" style={{ display:'flex',justifyContent:'center', width:'100%'}} >
                  <h4 className="font-alt" style={{textAlign:'center', width:'50%', margin:'auto'}}>About</h4>
                 </div>
            {/* <h5>
              An aspiring <span className="color-theme">Music Composer</span> based
              in <span className="color-theme">Greece</span>
            </h5> */}
            <p className="bio_text">
            Dimitris Mathiopoulos was born in Athens, Greece. In 2000 he started violin
lessons at the Athenaeum Conservatory under violin soloist A. Halapsis. For
several years he studied music theory, harmony, piano, chamber music and
viola, in addition to being a member of the Bios chamber orchestra of Athens. 
{width <= 993 && <span style={{display:'block',whiteSpace:'normal'}}>  <br/>    </span>} He completed his music studies in 2014 when he received a violin diploma with the highest distinction from the Hellenic Ministry of Culture. {width <= 993 && <span style={{display:'block',whiteSpace:'normal'}}>  <br/>    </span>} He has participated in music festivals in Greece and abroad and has attended masterclasses with renowned musicians such as Eduard Grach, Sergey Kravchenko and Vladimir Ivanov. <br/>{width <= 993 && <span style={{display:'block',whiteSpace:'normal'}}>  <br/>    </span>} As a professional musician (violinist and violist) he has participated in theatrical performances and musicals like: “Fiddler on the Roof”, “Nine the Musical”, “La La Land in Concert”, etc. <br/> {width <= 993 && <span style={{display:'block',whiteSpace:'pre'}}>      </span>} Dimitris also holds a Bachelor’s degree in Mathematics from the National and Kapodistrian University of Athens. <br/> <br /> <hr style={{width:'20%', marginTop:'15px'}}/> <br/> During the course of his studies he developed a growing interest in composition. This led him pursue additional music education and in 2020 he eventually obtained a Master’s degree from the Ionian University of Greece entitled: “Composition for Film, Theatre and the Performing Arts”. Since then, he has been involved in projects concerning film scoring, orchestration, score editing, music production, etc. <br/> {/* <h1>{width}</h1> */} {width <= 993 && <span style={{display:'block',whiteSpace:'pre'}}>      </span>} Most notably he has composed original music for the award-winning animated short film “The Wheel” (16th Athens Animfest - International Animation Festival).
            </p>
            {/* <div className="row about-list">
              <div className="col-md-6">
                <div className="media">
                  <label>BirthPlace</label>
                  <p>Athens, Greece</p>
                </div>
                <div className="media">
                  <label>Instruments</label>
                  <p>Piano, Harmony, Viola</p>
                </div>
                <div className="media">
                  <label>Bachelor's</label>
                  <p>Mathematics</p>
                </div>
                <div className="media">
                  <label>Masters</label>
                  <p>“Composition for Film, Theatre and the Performing Arts”</p>
                </div>
              </div> */}
              {/* End .col */}

              {/* <div className="col-md-6">
                <div className="media">
                  <label>Projects</label>
                  <p>film scoring,
                    orchestration, score editing, music production</p>
                </div>
                <div className="media">
                  <label>Achievements</label>
                  <p>composed original music for the award-winning animated
short film “The Wheel”</p>
                </div>
                <div className="media">
                  <label>Skype</label>
                  <p>skype.0404</p>
                </div>
                <div className="media">
                  <label>Freelance</label>
                  <p>Available</p>
                </div>
              </div> */}
              {/* End .col */}
            {/* </div> */}
            {/* End .row */}
            {/* <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="img/resume.png" download>
                Download CV
              </a>
            </div> */}
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
