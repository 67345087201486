import React, { useEffect, useState } from "react";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {


      if (window.pageYOffset >  2.7 *  window.screen.height) {
       
       
        // if(window.pageYOffset >= 3100  )
        console.log(window.pageXOffset);
        
        setIsVisible(true);
        // if(window.pageXOffset === 0 )
        // setIsVisible(false);

  
  // console.log(typeof(window.pageYOffset));

    
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <div onClick={scrollToTop} className="scroll_up">
          <span className="ti-angle-double-up"></span>
        </div>
      )}
    </>
  );
}
