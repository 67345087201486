import React,{useState} from "react";
import { useForm } from "react-hook-form";
//emailjs import
import emailjs from 'emailjs-com';
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';

const Contact = ({setAct}) => {

  //state
  const [checked, setChecked] = useState(false);
  const [focus,setFocus] = useState(false);



  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {

    console.log(data);
    // return ;
    // e.target.reset();
    console.log("Message submited: " + JSON.stringify(data));
    emailjs.sendForm('gmail','template_bc9mepm',e.target, 'user_L1WYSQtmbbGiV36lMCGWv').then((result)=>{
      console.log('result is', result.text);
    }, (error)=>{
      console.log('error ->',error.text);
    });

    e.target.reset();

  };



  //functions to send email



  return (
    <>


      <form className="contct-form" onSubmit={handleSubmit(onSubmit)} style={{background:'rgba(20,20,20,0.5)'}}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Full name"
                {...register("name", { required: true })}
              />
              <label className="form-label">Name</label>
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
              />
              <label className="form-label">Email</label>
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Subject"
                {...register("subject", { required: true })}
              />
              <label className="form-label">Subject</label>
              {errors.subject && (
                <span className="invalid-feedback">Subject is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group">
              <textarea
              style={{resize:'none'}}
                rows="4"
                className="form-control"
                placeholder="Type comment"
                {...register("comment", { required: true })}
              ></textarea>
              <label className="form-label">Comment</label>
              {errors.comment && (
                <span className="invalid-feedback">Comment is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group" style={{width:'100%', display:'flex', alignItems:'center'}}>
              
            <input type="checkbox" name="agree"   className="regular-checkbox" onChange={(e)=> setChecked(e.target.checked)}
           
            />
            <label htmlFor="agree" style={{marginLeft:'5px'}}>  Agree with <a class="btn" onClick={setAct}>terms and conditions</a></label>

            </div>
            {focus && !checked && <p> - Accept terms and conditions</p>}
          </div>

          <div className="col-12">
            <div className="btn-bar">
              {checked ? <button type="submit" className="px-btn px-btn-theme send-btn">Send your message</button>:
              <button onClick={()=> setFocus(true)} className="px-btn px-btn-theme send-btn">Send your message</button>}
              
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;
